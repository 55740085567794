/*-----------------------------------------------
 共通JS
-----------------------------------------------*/

$(function(){

// ページトップへ戻るのスクロール
  var $pagetop = $("#pageTop");

  if ($(window).width() > 768) $pagetop.hide();
  $(window).scroll(function(){
    if($(window).scrollTop() > 200){
      $pagetop.fadeIn();
    } else {
      $pagetop.fadeOut();
    }
  });

  $pagetop.click(function(){
    $("html,body").animate({scrollTop:0},1000);
    return false;
  });

// アンカーリンクを押すと、スクロール
  $('a[href^=#]:not([class*="modal"])').click(function() {
      // グローバルナビ（スマホ版）が開いていたら先に閉じる
      if ($(".navBtnArea").hasClass("active")) {
          $(".navBtnArea").click();
      }
      // スクロール処理
      var speed = 400;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? "html" : href);
      var position = target.offset().top;
      $("body,html").animate({scrollTop:position}, speed, "swing");
      return false;
  });

// ハンバーガーメニュー
  $(".navBtnArea").click(function() {
    $(this).toggleClass("active");
    $('html').toggleClass('scroll-prevent') // 背景固定
    if ($(this).hasClass("active")) {
        $(".gmenu").fadeIn("slow").addClass("active");
        $(".navToggle").addClass("active");
    } else {
        $(".gmenu").removeClass("active").fadeOut("slow");
        $(".navToggle").removeClass("active");
    }
  });

  //ユーザーエージェントを取得してパララックス無効のclassを付与
  // 変数
  var agent       = navigator.userAgent; // ユーザーエージェント
  var htmlElement = document.documentElement; // html要素

  // UserAgentで振り分けてhtmlにclassを振る
  if(agent.search("iPad") != -1){ htmlElement.className += 'ipad'; }
  if(agent.search("iPhone") != -1){ htmlElement.className += 'iphone'; }
  if(agent.search("Android") != -1){ htmlElement.className += 'android'; }

  // グローバルナビゲーションを固定する	
  $(window).scroll(function() {
    checkHeaderPosition();
  });

  function checkHeaderPosition(){
    var $topheader = $('#top header');
    if ($(window).scrollTop() > 100) {
      $topheader.addClass('fixedheader animated slideInDown');
    } else {
      $topheader.removeClass('fixedheader animated slideInDown');
    }
	var $fixheader = $('header .fix_wrap');
    if ($(window).scrollTop() > 100) {
      $fixheader.addClass('fixedheader_not_top animated slideInDown');
    } else {
      $fixheader.removeClass('fixedheader_not_top animated slideInDown');
    }

	var $fixheader_img = $('header .fix_wrap .logo img, header .fix_wrap_ja .logo img');
    if ($(window).scrollTop() > 100) {
      $fixheader_img.addClass('fixedheader_adjust');
    } else {
      $fixheader_img.removeClass('fixedheader_adjust');
    }

    var $fixheader_ja = $('header .fix_wrap_ja');
    if ($(window).scrollTop() > 100) {
      $fixheader_ja.addClass('fixedheader_not_top_ja animated slideInDown');
    } else {
      $fixheader_ja.removeClass('fixedheader_not_top_ja animated slideInDown');
    }

	var $fixheader_ja_lang = $('header .fix_wrap_ja .lang-btn');
    if ($(window).scrollTop() > 100) {
      $fixheader_ja_lang.addClass('fixedheader_adjust_ja');
    } else {
      $fixheader_ja_lang.removeClass('fixedheader_adjust_ja');
    }
  }
	
});
